<nav
class="navbar navbar-expand-lg navbar-light bg-white z-index-3 py-3 shadow-none navbar-transparent">
  <div class="container">
    <a class="navbar-brand" href="./" rel="tooltip" title="Shorter links for everyone" data-placement="bottom">
      <img src="@/assets/images/shortrLink_logo.png" style="height: 3rem; margin-top: -1rem;" />
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse justify-content-end" id="navigation">
      <div v-if="isAuthenticated">
        <ul class="navbar-nav navbar-nav-hover ms-auto">
          <div class="row ">
            <div class="col-auto m-auto">
              <a class="nav-item nav-link text-secondary" id="dashboard" href="./dashboard">
                Dashboard
              </a>
            </div>
            <div class="col-auto m-auto">
              <a class="nav-link text-secondary" href="https://github.com/R4h4/shortrLink" target="_blank">
                GitHub
              </a>
            </div>
            <div class="col-auto m-auto">
              <a class="nav-item nav-link text-secondary" href="./sign-out">
                Logout
              </a>
            </div>
            <div class="col-auto">
              <div class="bg-white border-radius-lg d-flex me-2">
                <button type="button" class="btn bg-gradient-primary mb-0" data-bs-toggle="modal" data-bs-target="#exampleModal">
                  Create new link
                </button>
              </div>
            </div>
          </div>
        </ul>
      </div>
      <div v-else>
        <ul class="navbar-nav navbar-nav-hover ms-auto">
          <div class="row justify-content-end">
            <div class="col-auto m-auto">
              <a class="nav-link text-secondary" href="https://github.com/R4h4/shortrLink" target="_blank">
                GitHub
              </a>
            </div>
            <div class="col-auto m-auto">
              <a class="nav-link text-secondary" href="./login">
                Login
              </a>
            </div>
            <div class="col-auto">
              <div class="bg-white border-radius-lg d-flex me-2">
                <a href="./sign-up">
                  <button class="btn  btn-white text-primary mb-0">Register</button>
                </a>
              </div>
            </div>
          </div>
        </ul>
      </div>
    </div>
  </div>
</nav>
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="false">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Create new link</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <shortrLink class="mt-2" />
      </div>
    </div>
  </div>
</div>
<link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700" rel="stylesheet" />